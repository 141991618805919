import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_GOLLAMA_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${process.env.REACT_APP_GOLLAMA_API_KEY}`,
  },
});

export const generateResponse = async (prompt) => {
  const response = await api.post('/chat/completions', {
    model: 'llama-2',
    prompt,
    temperature: 0.5, // Adjust as needed
  });
  return response.data.choices[0].text;
};

export const listModels = async () => {
  const response = await api.get('/models');
  return response.data;
};

export const generateEmbeddings = async (text) => {
  const response = await api.post('/embeddings', { text });
  return response.data.embeddings;
};
