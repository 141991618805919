import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate from react-router-dom
import './SecretTokenBox.css';

const SecretTokenBox = ({ closePopup }) => {
    const [secretToken, setSecretToken] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/validate_secret_token.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({ 'secret-token': secretToken })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            if (result.success) {
                navigate('/protected-content'); // Redirect to /protected-content
            } else {
                setError('Invalid Secret Token. Please try again.');
            }
        } catch (error) {
            setError('There was a problem with the request: ' + error.message);
        }
    };

    return (
        <div className="secret-token-box">
            <div className="header">
                <h3>Enter Secret Token</h3>
                <button onClick={closePopup} className="close-button">&times;</button>
            </div>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Enter Secret Token"
                    maxLength="64"
                    value={secretToken}
                    onChange={(e) => setSecretToken(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

export default SecretTokenBox;
