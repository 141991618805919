import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
// import BlogPage from './pages/BlogPage'; // Uncomment when the BlogPage is ready
// import Navbar from './components/Navbar'; // Uncomment if you have a Navbar component
import ChatInterface from './components/ChatInterface';
import './App.css'; // Uncomment if you have global styles

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define the routes for your application */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* Add additional routes for other components as needed */}
          {/* <Route path="/blog" element={<BlogPage />} /> */}
          <Route path="/chat" element={<ChatInterface />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
