import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import './HomePage.css';
import SecretTokenBox from '../components/SecretTokenBox';
//import Navbar from '../components/Navbar';
import backgroundImage from '../assets/background.webp';
import logo from '../assets/logo.webp';
import colorMapImage from '../assets/colorMap.png';
import bumpMapImage from '../assets/bumpMap.png';
import cloudMapImage from '../assets/cloudMap.png';

function HomePage() {
  const logoRef = useRef(null);
  const modelRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const globeRef = useRef(null);
  const cloudsRef = useRef(null);
  const [isTokenBoxOpen, setIsTokenBoxOpen] = useState(false);

  useEffect(() => {
    const logoContainer = logoRef.current;
    const modelContainer = modelRef.current;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(60, logoContainer.clientWidth / logoContainer.clientHeight, 0.1, 1000);
    camera.position.set(0, 0, 3);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(logoContainer.clientWidth, logoContainer.clientHeight);
    renderer.setClearColor(0x000000, 0);
    modelContainer.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableRotate = true;
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.target.set(0, 0, 0);
    controls.update();

    const textureLoader = new THREE.TextureLoader();
    const colorMap = textureLoader.load(colorMapImage);
    const bumpMap = textureLoader.load(bumpMapImage);
    const cloudMap = textureLoader.load(cloudMapImage);

    const earthGeometry = new THREE.SphereGeometry(0.83, 64, 64);
    const earthMaterial = new THREE.MeshStandardMaterial({
      map: colorMap,
      bumpMap: bumpMap,
      bumpScale: 10.0,
      metalness: 0.4,
      roughness: 0.5,
    });
    const globe = new THREE.Mesh(earthGeometry, earthMaterial);
    globeRef.current = globe;
    scene.add(globe);

    const cloudGeometry = new THREE.SphereGeometry(0.84, 64, 64);
    const cloudMaterial = new THREE.MeshStandardMaterial({
      map: cloudMap,
      transparent: true,
      opacity: 0.8,
    });
    const clouds = new THREE.Mesh(cloudGeometry, cloudMaterial);
    cloudsRef.current = clouds;
    scene.add(clouds);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
    camera.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.0);
    directionalLight.position.set(5, 5, 5);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;
    directionalLight.shadow.bias = -0.005;
    camera.add(directionalLight);

    scene.add(camera);

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    globe.castShadow = true;
    globe.receiveShadow = true;

    clouds.castShadow = false;
    clouds.receiveShadow = true;

    const animate = () => {
      requestAnimationFrame(animate);

      globe.rotation.y += 0.005;
      clouds.rotation.y += 0.005;

      controls.update();
      renderer.render(scene, camera);
    };

    animate();
    setLoading(false);

    const handleResize = () => {
      camera.aspect = logoContainer.clientWidth / logoContainer.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(logoContainer.clientWidth, logoContainer.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    const tokenBoxTimeout = setTimeout(() => {
      setIsTokenBoxOpen(true);
    }, 5000);  // Update to control the token box display

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(tokenBoxTimeout);
      if (modelContainer) {
        modelContainer.removeChild(renderer.domElement);
      }
    };
  }, []);

  const closeTokenBox = () => {
    setIsTokenBoxOpen(false);
  };

  return (
    <div className="homePage">
     
      <div className="mainContent" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo-container" ref={logoRef}>
          <img src={logo} alt="Logo" className="logo-image" />
          <div className="model-container" ref={modelRef}></div>
          {loading && <div className="loading">Loading...</div>}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {isTokenBoxOpen && <SecretTokenBox closePopup={closeTokenBox} />}  
        </Suspense>
      </div>

      {/* Footer - Removed */}
    </div>
  );
};

export default HomePage;